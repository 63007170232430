@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,600;1,300&display=swap');
@import 'dark';

* {
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
  font-family: 'IBM Plex Serif', serif;
  @media (max-width: 370px) {
    font-size: 88%;
  }
}

.highlight,
.highlight__bg {
  text-decoration: none;
  line-height: 1.9rem;
  box-shadow: inset 0 -4px 0 #f7d652;
  color: rgba(0, 0, 0, 0.8);
}

.highlight__bg {
  border-bottom: 3px solid #f7d652;
  background: #f7d652;
  text-decoration: none;
}

.portfolio,
.portfolio__main {
  position: relative;
  padding: 70px 20px 50px;
}

.portfolio__main {
  max-width: 760px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.portfolio__main article {
  margin: 0;
  padding: 0;
}

.portfolio__main article p.p__intro {
  font-size: 1.37rem;
  margin-top: 0;
  line-height: 1.9rem;
  margin-bottom: 1rem;

  a {
    cursor: pointer;
    color: inherit;
    white-space: nowrap;
  }
}
